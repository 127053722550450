<template>
  <div class="container">
    <navbar title="付款"></navbar>
    <div class="top-container">
      <div class="price-container">
        <div class="price">
          <div class="price">￥</div>
          <div class="price-num">{{orderItem.order_amount}}</div>
        </div>
      </div>
      <pay-select class="pay-type-select"></pay-select>
    </div>
    <div class="bottom-operate-placeholder"></div>
    <div class="bottom-operate">
      <!-- 普通支付不用密码 -->
      <div
        class="operate-button"
        @click="confirmPay"
      >确认付款</div>
    </div>
    <credit-apply v-model="creditApplyShow"></credit-apply>
    <set-password v-model="setPasswordShow"></set-password>
    <confirm-password
      v-model="confirmPasswordShow"
      @confirm="pay"
    ></confirm-password>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import PaySelect from "./components/pay-select.vue";
import CreditApply from "./components/credit-apply.vue";
import SetPassword from "./components/set-password.vue";
import ConfirmPassword from "./components/confirm-password.vue";
import { orderDetail, payment } from "@/api/order.js";
import { userDetail } from "@/api/index.js";
import AES from '@/lib/aes.js'

export default {
  name: "pay",
  components: {
    Navbar,
    PaySelect,
    CreditApply,
    SetPassword,
    ConfirmPassword,
  },
  provide() {
    return {
      p: this,
    };
  },
  inject: ['_app'],
  data() {
    return {
      pay_type: 0,
      setPasswordShow: false,
      confirmPasswordShow: false,
      creditApplyShow: false,
      orderId: 0,
      orderItem: {},
      user: {},
    };
  },
  mounted() {
    if (this.$route?.params?.orderId) {
      this.orderId = this.$route.params.orderId;
      this.getOrderItem();
      this.setPayType(0);
      this.getUser(()=>{
        if(this.showCredit) this.pay_type = 1;
      });
    }
  },
  methods: {
    setPayType(type) {
      this.pay_type = type;
      if(type == 1){
        this.getUser();
      }
    },
    getUser(callback = null) {
      userDetail({type: 'paramsOne'}).then(res => {
        if (res.code == 0) {
          this.user = res.data;
          if (callback != null) {
            callback();
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    //需要支付密码
    isPassword() {
      return this.pay_type == 1;
    },
    getOrderItem() {
      orderDetail({ id: this.orderId }).then(res => {
        if (res.code == 0) {
          this.orderItem = res.data;
          if (this.orderItem['order_status'] != 0 || this.orderItem['payDetail']['payType'] != 1) {
            this.$toast({
              message: '订单状态不可支付', onClose: () => {
                this.$back();
              }
            })
          }
        } else {
          this.$toast({
            message: res.msg, onClose: () => {
              this.$back();
            }
          });
        }
      })
    },
    //点击确认支付
    confirmPay() {
      if(this.pay_type == 1 && this.user.applyStatus != 2){
        this.creditApplyShow = true;
        return false;
      }
      if(this.pay_type == 1 && this.user.applyStatus == 2 && this.lineMoney < this.orderItem.order_amount){
        return this.$toast('授信额度不足');
      }
      if (this.isPassword()) {
        if (this.user.password == 1) {
          this.confirmPasswordShow = true;
        } else {
          this.setPasswordShow = true;
        }
      } else {
        this.pay();
      }
    },
    //支付
    pay(password = '') {
      let data = {
        id: this.orderId,
        type: this.pay_type,
      };
      if(this.pay_type == 0){
        data['bank_id'] = this.orderItem.payDetail.due_bank_id;
      }
      if (this.isPassword()) {
        data['pwd'] = AES.encrypt(password + '-' + parseInt(new Date().getTime()/1000));
      }
      payment(data).then(res => {
        if (res.code == 0) {
          if(this.pay_type == 1){  //授信支付
            this._app.reloadPrev();
            this._app.reload('order');
            this.$router.push('/order/detail/'+this.orderItem.id+'?_replace=true');
          }else{
            this.$router.push('/pay/voucher/'+this.orderItem.id+'?_replace=true');
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
  },
  computed: {
    //剩余额度
    lineMoney() {
      return this.user.remaining;
    },
    //显示授信
    showCredit(){
      return this.$store.state.user?.user?.isMain || this.user.applyStatus == 2;
    }
  },
  watch: {

  }
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .top-container {
    background: url(~@/assets/images/pay/top_bg.png) no-repeat;
    background-size: 100% 275px;
    min-height: 296px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .price-container {
      // flex: 1;
      height: 146px;
      display: flex;
      align-items: center;
    }
    .price {
      display: flex;
      align-items: flex-end;
      color: #e81000;
      .price-unit {
        font-size: 36px;
      }
      .price-num {
        font-size: 68px;
        line-height: 70px;
        font-weight: bold;
      }
    }
    .pay-type-select {
      // height: 120px;
      width: calc(100% - 40px);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      padding: 0px;
      box-sizing: border-box;
    }
  }
  .bottom-operate-placeholder {
    @include safe-area-inset-bottom-height(148px);
  }
  .bottom-operate {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 148px;
    width: 100%;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    @include safe-area-inset-bottom;
    .operate-button {
      width: 692px;
      height: 88px;
      background: #1aa3ff;
      border-radius: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
